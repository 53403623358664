//
// popover.js
// Theme module
//

import { Popover } from 'bootstrap';

export function init() {
  const popovers = document.querySelectorAll('[data-bs-toggle="popover"]');

  popovers.forEach(popover => {
    new Popover(popover);
  });
}