//
// inputmask.js
// Theme module
//

import Inputmask from 'inputmask';

export function init() {
  const toggles = document.querySelectorAll('[data-inputmask]');

  const options = {
    rightAlign: false,
  };

  Inputmask(options).mask(toggles);
}

// Make available globally
window.Inputmask = Inputmask;
