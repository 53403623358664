//
// popover.js
// Theme module
//

import { Tooltip } from 'bootstrap';

export function init() {
  const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

  tooltips.forEach(tooltip => {
    new Tooltip(tooltip);
  });
}