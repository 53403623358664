//
// autosize.js
// Dashkit module
//

import autosize from 'autosize';

export function init() {
  const toggles = document.querySelectorAll('[data-autosize]');

  toggles.forEach((toggle) => {
    autosize(toggle);
  });
}

// Make available globally
window.autosize = autosize;
