// import modules and make them available globally
import './autosize';
import './bootstrap';
import './chart';
import './choices';
import './dropzone';
import './flatpickr';
import './highlight';
import './inputmask';
import './list';
import './quill';
import './sortable';

// init modules on each page load
import { init as initAutosize } from './autosize';
import { init as initChart } from './chart';
import { init as initChecklist } from './checklist';
import { init as initChoices } from './choices';
import { init as initDropzone } from './dropzone';
import { init as initFlatpickr } from './flatpickr';
import { init as initHighlight } from './highlight';
import { init as initInputmask } from './inputmask';
import { init as initKanban } from './kanban';
import { init as initList } from './list';
import { init as initMap } from './map';
import { init as initNavbarCollapse } from './navbar-collapse';
import { init as initNavbarDropdown } from './navbar-dropdown';
import { init as initPopover } from './popover';
import { init as initQuill } from './quill';
import { init as initTooltip } from './tooltip';
import { init as initWizard } from './wizard';

document.addEventListener('turbolinks:load', () => {
  initAutosize();
  initChart();
  initChecklist();
  initChoices();
  initDropzone();
  initFlatpickr();
  initHighlight();
  initInputmask();
  initKanban();
  initList();
  initMap();
  initNavbarCollapse();
  initNavbarDropdown();
  initPopover();
  initQuill();
  initTooltip();
  initWizard();
});